import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcmoduleForm",
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      model: _vm.item,
      selfUpdate: true
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "模块",
      prop: "modulartype",
      rules: {
        required: true,
        message: "请选择模块"
      }
    }
  }, [_c("a-select", {
    model: {
      value: _vm.item.modulartype,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "modulartype", $$v);
      },
      expression: "item.modulartype"
    }
  }, _vm._l(_vm.moduleList, function (item, index) {
    return _c("a-select-option", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "状态",
      prop: "isshow",
      rules: {
        required: true,
        message: "请选择状态!"
      }
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isDisabled
    },
    model: {
      value: _vm.item.isshow,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "isshow", $$v);
      },
      expression: "item.isshow"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_c("span", [_vm._v("展示")])]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("不展示")])])], 1)], 1), _vm.item.modulartype == 2 ? _c("a-form-model-item", {
    attrs: {
      label: "类型",
      prop: "type",
      rules: {
        required: true,
        message: "请选择类型"
      }
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isDisabled
    },
    model: {
      value: _vm.item.type,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  }, _vm._l(_vm.newsTypeList, function (item, index) {
    return _c("a-radio", {
      key: index,
      attrs: {
        value: item.id
      }
    }, [_c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1)], 1) : _vm._e(), _vm.item.modulartype == 3 || _vm.item.modulartype == 5 ? _c("a-form-model-item", {
    attrs: {
      label: "".concat(_vm.item.modulartype == 3 ? "项目" : "NFT", "\u6570\u636E")
    }
  }, [_c("draggable", {
    staticClass: "list-group",
    attrs: {
      list: _vm.item.list || []
    }
  }, _vm._l(_vm.item.list || [], function (element, index) {
    return _c("a-form-model-item", {
      key: index,
      staticClass: "list-group-item",
      attrs: {
        prop: "list.".concat(index, ".content"),
        rules: {
          required: _vm.item.modulartype == 3 ? true : false,
          message: "网址链接格式错误",
          pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/
        }
      }
    }, [_c("a-input", {
      attrs: {
        placeholder: "".concat(_vm.item.modulartype == 3 ? "项目Launchpad" : "NFT", "\u7F51\u5740\u94FE\u63A5")
      },
      model: {
        value: element.content,
        callback: function callback($$v) {
          _vm.$set(element, "content", $$v);
        },
        expression: "element.content"
      }
    }), _c("a-icon", {
      staticStyle: {
        fontSize: "20px",
        "margin-left": "10px",
        cursor: "all-scroll"
      },
      attrs: {
        type: "drag"
      }
    }), index === 0 ? _c("a-icon", {
      staticStyle: {
        fontSize: "20px",
        "margin-left": "10px"
      },
      attrs: {
        type: "plus"
      },
      on: {
        click: function click($event) {
          return _vm.toggle("add");
        }
      }
    }) : _vm._e(), index !== 0 ? _c("a-icon", {
      staticStyle: {
        fontSize: "20px",
        "margin-left": "10px"
      },
      attrs: {
        type: "minus"
      },
      on: {
        click: function click($event) {
          return _vm.toggle("sub", index);
        }
      }
    }) : _vm._e()], 1);
  }), 1)], 1) : _vm._e()], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };