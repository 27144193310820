var columns = [{
  title: '排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '10%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: '模块',
  dataIndex: 'modulartype',
  key: 'modulartype',
  width: '25%',
  scopedSlots: {
    customRender: 'modulartype'
  }
}, {
  title: '类型',
  dataIndex: 'type',
  key: 'type',
  width: '25%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: '状态',
  dataIndex: 'isshow',
  key: 'isshow',
  width: '20%',
  scopedSlots: {
    customRender: 'isshow'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: '20%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };