import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "approvalStyle"
  }, [_c("a-table", {
    staticStyle: {
      "margin-top": "24px",
      background: "#fff"
    },
    attrs: {
      loading: _vm.loading,
      pagination: false,
      columns: _vm.columns,
      dataSource: _vm.dataSource,
      customRow: _vm.customRow,
      rowKey: function rowKey(it, i) {
        return it.id;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "sort" ? _c("span", [_c("span", [_vm._v(" " + _vm._s(record.sort || "---") + " ")])]) : key == "modulartype" ? _c("span", [_vm._v(" " + _vm._s(_vm.moduleListObj[text] || "---") + " ")]) : key == "type" ? _c("span", [_vm._v(" " + _vm._s(record.modulartype == 2 ? _vm.newsTypeListObj[text] || "---" : "---") + " ")]) : key == "isshow" ? _c("span", [_vm._v(" " + _vm._s(text == 1 ? "不展示" : "展示") + " ")]) : key == "action" ? _c("span", {
            staticClass: "btn-actions"
          }, [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["dcModule:btn:edit"],
              expression: "['dcModule:btn:edit']"
            }],
            on: {
              click: function click($event) {
                return _vm.editorClick(record);
              }
            }
          }, [_vm._v("编辑")]), _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["dcModule:btn:delete"],
              expression: "['dcModule:btn:delete']"
            }],
            on: {
              click: function click($event) {
                return _vm.handleDelete(record);
              }
            }
          }, [_vm._v("删除")])]) : _vm._e()];
        }
      };
    })], null, true)
  }), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible,
      loading: _vm.loading
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  }), _c("DPopup", {
    attrs: {
      title: _vm.modalTitle,
      comVisible: _vm.modalVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Form", {
          attrs: {
            editId: _vm.editId
          },
          on: {
            cancel: _vm.handleModalCancel,
            submit: _vm.handleFormSubmit
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };