import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import { mapState } from "vuex";
import { DcModuleApi } from '@/api';
import draggable from "vuedraggable";
import { deepClone } from "@/utils/help";
export default {
  name: "dcForm",
  components: {
    draggable: draggable
  },
  props: {
    editId: [String, Number],
    bannerTitle: String
  },
  watch: {
    'item.modulartype': function itemModulartype(value) {
      if ((value == 3 || value == 5) && (!this.item.list || this.item.list.length == 0)) {
        this.toggle('add');
      }
    }
  },
  data: function data() {
    return {
      isEdit: '',
      preVisible: false,
      previewImage: "",
      previewVisible: false,
      fetching: false,
      currentId: "new",
      disabledLoading: false,
      dragging: false,
      item: {
        modulartype: 1,
        isshow: 0,
        type: 0,
        list: []
      }
    };
  },
  computed: _objectSpread({
    isDisabled: function isDisabled() {
      return this.bannerTitle == '查看Banner';
    }
  }, mapState('dcModule', ['moduleList', 'bannerTypeList', 'newsTypeList'])),
  created: function created() {
    if (this.editId) {
      this.find();
    }
  },
  mounted: function mounted() {},
  methods: {
    find: function find() {
      var _this = this;
      this.disabledLoading = true;
      DcModuleApi.find({
        id: this.editId
      }).then(function (res) {
        _this.item = res;
        _this.disabledLoading = false;
      }).catch(function (err) {
        // this.disabledLoading = true;
        _this.toast(err || "接口报错", "error");
      });
    },
    cancle: function cancle() {
      this.$emit('cancel');
    },
    submit: function submit() {
      var _this2 = this;
      if (this.disabledLoading) return;
      this.$refs.dcmoduleForm.validate(function (valid) {
        if (valid) {
          _this2.disabledLoading = true;
          var params = deepClone(_this2.item);
          // console.log(params, params.modulartype)
          if (params.modulartype != 2) {
            delete params.type;
          }
          if (params.modulartype != 3 && params.modulartype != 5) {
            delete params.list;
          }
          // console.log(params)
          if (params.list != undefined) {
            // console.log(params.list)
            var list = [];
            params.list.forEach(function (item, index) {
              item.sort = index + 1;
              if (item.content) {
                if (_this2.editId) {
                  list.push({
                    content: item.content,
                    id: item.id
                  });
                } else {
                  list.push({
                    content: item.content
                  });
                }
              }
            });
            params.list = list;
          }
          if (params.id) {
            delete params.id;
          }
          // console.log(params);
          // return

          var method = _this2.editId ? 'update' : 'add';
          if (_this2.editId) {
            params.id = _this2.editId;
          }
          DcModuleApi[method](params).then(function (res) {
            _this2.toast("操作成功", "success");
            _this2.$emit('submit');
          }).catch(function (err) {
            _this2.disabledLoading = false;
            _this2.toast(err || "操作失败", "error");
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    toggle: function toggle(type, index) {
      if (type == 'add') {
        if (!this.item.list || this.item.list.length == 0) {
          this.item.list = [];
        }
        this.item.list.push({
          id: 0,
          content: '',
          sort: 0
        });
      } else {
        this.item.list = this.item.list.filter(function (item, ind) {
          // console.log(index, ind)
          return index != ind;
        });
      }
    }
  }
};