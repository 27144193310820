var searchKeys = [
// {
//     key: "name",
//     label: "banner名称",
//     placeholder: "请输入",
//     required: false,
//     rules: [],
//     input: true
// },

{
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };